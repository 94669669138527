.profile-input-wrap .profile-label {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
}

.profile-input {
    width: 100%;
    padding: 16px;
}

.profile-input input {
    padding: 16px;
}

.profile-input label {
    color: var(--text-color) !important;
    font-family: 'Satoshi-Regular' !important;
    letter-spacing: 0.8px !important;
    font-size: 16px !important;
}

.password-switch-wrap {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.profile-password-switch {
    margin-left: 0 !important;
}

.password-switch-wrap h6 {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
}

.form-switch {
    padding-left: 0;
}

.profile-update-btn {
    float: right;
    border: 0;
    background-color: var(--global-color);
    color: var(--white);
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 40px;
    letter-spacing: 0.8px;
}

.input-adornment-custom-profile {
    margin-top: 4px !important;
    margin-right: -7px !important;
}