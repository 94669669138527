.header-text-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-text {
    font-size: 26px;
    color: var(--global-color);
}

.add-distributor-btn {
    background-color: var(--global-color);
    color: var(--white);
    border-radius: 8px;
    border: 0;
    font-size: 16px;
    letter-spacing: 0.8px;
    padding: 15px 25px;
}

.distributer-input-wrap .distributer-label {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
}

.distributer-input {
    width: 100%;
}

.distributer-input label {
    color: var(--text-color) !important;
    font-family: 'Satoshi-Regular' !important;
    letter-spacing: 0.8px !important;
    font-size: 16px !important;
}

.distributer-password-input {
    width: 100%;
}

.distributer-password-label {
    color: var(--text-color) !important;
    font-family: 'Satoshi-Regular' !important;
    letter-spacing: 0.8px !important;
    font-size: 16px !important;
}

.distributer-country-dropdown {
    width: 100%;
}

.distributers-btn-wrap {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 30px;
    margin-top: 50px;
}


.distributers-btn-wrap .cancel-btn {
    border: 1px solid var(--global-color);
    background-color: transparent;
    border-radius: 8px;
    font-size: 16px;
    padding: 20px 150px;
    letter-spacing: 0.8px;
}

.distributers-btn-wrap .submit-btn {
    border: 0;
    background-color: var(--global-color);
    color: var(--white);
    border-radius: 8px;
    font-size: 16px;
    padding: 20px 150px;
    letter-spacing: 0.8px;
}

.invalid-message {
    color: red;
    letter-spacing: 1px;
    font-size: 16px;
    margin-top: 5px;
}

.input-adornment-custom {
    margin-top: 2px !important; 
    margin-right: -7px !important; 
}


/* input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    background-color: white !important;
    color: #000 !important;
}


input:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    background-color: white !important;
    color: #000 !important;
}


input:-moz-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
    background-color: white !important;
    color: #000 !important;
} */



@media(max-width:1024px) {
    .distributers-btn-wrap {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0;
        margin-top: 20px;
    }
}

@media(max-width:899px) {
    .distributer-input-wrap .distributer-label {
        /* margin-bottom: 20px; */
    }

    .distributers-btn-wrap {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0;
        margin-top: 20px;
    }

    .distributers-btn-wrap .cancel-btn {
        padding: 15px 100px;
    }

    .distributers-btn-wrap .submit-btn {
        padding: 15px 100px;
    }
}

@media(max-width:767px) {

    .header-text {
        font-size: 22px;
    }
}

@media(max-width:576px) {

    .sidebar-open-text {
        width: max-content !important;
    }

    .sidebar-open-distributer {
        width: max-content !important;
    }

    .sidebar-close-distributer {
        width: 100% !important;
    }

    .sidebar-open-distributer-btns-wrap {
        align-items: baseline !important;
        justify-content: start !important;
    }

    .header-text {
        font-size: 20px !important;
    }

    .distributers-btn-wrap .cancel-btn {
        padding: 15px 50px;
    }

    .distributers-btn-wrap .submit-btn {
        padding: 15px 50px;
    }

    .distributer-input-wrap .distributer-label {
        margin-bottom: 0;
    }

    .header-text-wrap {
        flex-direction: column;
        gap: 10px;
        align-items: start;
    }
}

@media(max-width:420px) {
    .distributers-btn-wrap {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0;
        margin-top: 20px;
    }

    .distributers-btn-wrap .submit-btn {
        margin-top: 10px;
    }
}