.back-to-login-btn {
    width: 100%;
}

.back-to-login-btn button {
    width: 100%;
    background-color: var(--global-color);
    border: none;
    border-radius: 12px;
    color: #fff;
    padding: 20px;
    font-size: 16px;
    letter-spacing: 1.5px;
    font-family: 'Satoshi-Regular' !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

@media (max-width: 576px) {
    .back-to-login-btn button {
        padding: 15px;
        font-size: 15px;
    }
}