.transaction-input-wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: column;
}

.transaction-input-wrap .transaction-label {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
}

.transaction-input-wrap .input-wrap {
    width: 100%;
}

.transaction-input-wrap input {
    border: 0;
    width: 100%;
    border-radius: 8px;
    padding: 16px;
}

.transaction-date-label p {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    font-family: 'Satoshi-Regular';
}

.transaction-date-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: none;
}

.transaction-date-input .MuiOutlinedInput-root {
    background-color: #fff;
    border-radius: 8px;
    margin-top: 7px;
}


.transaction-list-table-wrap {
    width: 100% !important;
    overflow: auto;
}


.transaction-list-table-header-bg {
    background-color: #EFF7FF;
    padding: 0px 0px 0px 10px !important;
}

.transaction-list-table-header-bg .header-text {
    color: var(--accent-color) !important;
    /* font-family: 'Satoshi-Bold' !important; */
    font-size: 14px !important;
    letter-spacing: 0.5px !important;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.transaction-list-table-header-bg .header-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.transaction-list-table-header-bg .up-icon {
    margin-top: 15px;
    color: #79A4D9;
}

.transaction-list-table-header-bg .down-icon {
    margin-top: -22px;
    color: #79A4D9;
}

.transaction-list-table td {
    font-family: 'Satoshi-Regular' !important;
    font-size: 14px !important;
    font-weight: 600;
    letter-spacing: 0.5px;
    padding: 14px;
    text-align: center;
    border: 0;
}

.transaction-list-table tr:nth-child(even) {
    background-color: #F7F7F7;
}

.transaction-list-table .transaction-type {
    border: 0;
    background-color: #D9D9D9;
    color: #000;
    border-radius: 15px;
    font-family: 'Satoshi-Regular' !important;
    font-size: 14px !important;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center;
    width: 60px;
    padding: 5px 15px 5px 15px;
}
.edit-icon{
    font-size: 25px;
    cursor: pointer;
}

@media(max-width:576px) {
    .transaction-list-form {
        width: max-content;
        overflow-x: auto;
    }
}