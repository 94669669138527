.change-passowrd-input-wrap .change-password-label {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
}

.profile-input {
    width: 100%;
}

.profile-input label {
    color: var(--text-color) !important;
    font-family: 'Satoshi-Regular' !important;
    letter-spacing: 0.8px !important;
    font-size: 16px !important;
}

.password-switch-wrap {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.update-password-btn {
    float: right;
    border: 0;
    background-color: var(--global-color);
    color: var(--white);
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 20px;
    letter-spacing: 0.8px;
    margin-top: 20px;
}