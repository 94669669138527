.navbar-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    box-shadow: 4px 6px 6px 0px rgba(100, 100, 100, 0.1);
    padding: 15px 20px 15px 20px;
}

.menu-wrap {
    display: flex;
    align-items: center;
    gap: 50px;
}

.navbar-logo {
    /* width: 150px; */
}

.profile-wrap {
    display: flex;
    align-items: center;
    gap: 50px;
}

.profile-wrap h6 {
    font-size: 14px;
    color: #0B151D;
}

.profile-img-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}

.profile-img-wrap h6 {
    font-size: 14px;
    color: #0B151D;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.menu-icon {
    font-size: 20px;
    color: var(--global-color);
    cursor: pointer;
}


.wrapper {
    align-items: stretch;
    display: flex;
    width: 100%;
}

#sidebar {
    max-width: 264px;
    min-width: 264px;
    transition: all 0.35s ease-in-out;
    z-index: 1111;
    background-color: var(--global-color);
    padding-top: 100px;
    height: 100vh;
    overflow-y: auto;
}


#sidebar.collapsed {
    margin-left: -264px;
}

.main {
    /* display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    transition: all 0.35s ease-in-out; */
}

main {
    width: 100%;
    padding: 90px 0px 0px 0px;
    overflow: auto;
}

.sidebar-logo {
    padding: 1.15rem 1.5rem;
}

.sidebar-logo a {
    color: #e9ecef;
    font-size: 1.25rem;
    font-weight: 600;
}

.sidebar-nav {
    padding: 0;
}

.sidebar-icon {
    vertical-align: sub;
}

.sidebar-header {
    color: #e9ecef;
    font-size: .75rem;
    padding: 1.5rem 1.5rem .375rem;
}

a.sidebar-link {
    padding: 15px 23px;
    color: #e9ecef;
    position: relative;
    display: block;
    font-size: 18px;
    letter-spacing: 0.5px;
}

.sidebar-link[data-bs-toggle="collapse"]::after {
    border: solid;
    border-width: 0 .075rem .075rem 0;
    content: "";
    display: inline-block;
    padding: 2px;
    position: absolute;
    right: 1.5rem;
    top: 1.4rem;
    transform: rotate(-135deg);
    transition: all .2s ease-out;
}

.menu-dropdown .sidebar-item {
    position: relative;
    padding-left: 60px;
}

.menu-dropdown li::before {
    content: "\2022";
    position: absolute;
    left: 60px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
}

.sidebar-link[data-bs-toggle="collapse"].collapsed::after {
    transform: rotate(45deg);
    transition: all .2s ease-out;
}

.content {
    flex: 1;
    max-width: 100vw;
    width: 100vw;
}


.sidebar-item {
    transition: background-color 0.3s ease;
}

.sidebar-item li:hover {
    background-color: #3B5776;
}

.sidebar-item:hover {
    background-color: #3B5776;
}

.bg-navbar {
    background-color: var(--white) !important;
}


.logout-confimartion.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}

.logout-confimartion.popup {
    background-color: white;
    padding: 50px;
    border-radius: 22px;
    text-align: center;
    z-index: 1000;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 35px;
}

.logout-confimartion.popup p {
    color: #000;
    font-size: 26px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 20px;
    letter-spacing: 0.5px;
}

.logout-confirmation-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.logout-confirmation-btns .cancel-btn {
    border: 1px solid var(--global-color);
    background-color: transparent;
    border-radius: 8px;
    font-size: 16px;
    padding: 20px 40px;
    letter-spacing: 0.8px;
    font-weight: 600;
}


.logout-confirmation-btns .logout-btn {
    border: 0;
    background-color: #0B151D;
    color: var(--white);
    border-radius: 8px;
    font-size: 16px;
    padding: 20px 40px;
    letter-spacing: 0.8px;
}

@media(max-width:1199px) {
    .logout-confimartion.popup-overlay {
        z-index: 9999;
    }
}


@media (min-width:768px) {
    .content {
        width: auto;
    }
}

@media(max-width:767px) {
    .navbar-wrap {
        overflow-x: auto;
        white-space: nowrap;
        gap: 35px;
    }

    .logout-confimartion.popup-overlay {
        z-index: 9999;
    }

    .logout-confimartion.popup {
        width: 60%;
        gap: 25px;
    }

    .logout-confimartion.popup p {
        font-size: 20px;
        letter-spacing: 0.5px;
        line-height: 30px;
    }

    .logout-confimartion.popup {
        padding: 25px;
    }

    .logout-confirmation-btns .cancel-btn {
        padding: 15px 30px;
    }

    .logout-confirmation-btns .logout-btn {
        padding: 15px 30px;
    }

}

@media(max-width:576px) {
    a.sidebar-link {
        font-size: 16px;
    }

    .sidebar-icon {
        width: 20px;
    }

    .logout-confimartion.popup {
        width: 75%;
    }

}

@media(max-width:400px) {
    .logout-confimartion.popup {
        width: 90%;
        gap: 20px;
    }

}