.retailer-input-wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: column;
}


.retailer-input-wrap .retailer-label {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
}

.retailer-input-wrap .input-wrap {
    width: 100%;
}

.retailer-input-wrap input {
    border: 0;
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    /* padding: 16px; */
    /* padding: 16px 16px 14px 16px; */
}

.custom-label p {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    font-family: 'Satoshi-Regular';
}

.custom-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: none;
}

.custom-input .MuiOutlinedInput-root {
    background-color: #fff;
    border-radius: 8px;
}


.retailer-btns-wrap {
    display: flex;
    align-items: baseline;
    justify-content: end;
    gap: 18px;
    margin-top: 30px;
}

.retailer-btns-wrap .refresh-icon {
    font-size: 45px;
    color: var(--global-color);
    border: 1px solid #3B5776;
    border-radius: 8px;
    padding: 10px;
}

.retailer-btns-wrap .cancel-btn {
    border: 1px solid var(--global-color);
    background-color: transparent;
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 65px;
    letter-spacing: 0.8px;
}

.retailer-btns-wrap .submit-btn {
    border: 0;
    background-color: var(--global-color);
    color: var(--white);
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 65px;
    letter-spacing: 0.8px;
}

.retailer-list-table-wrap {
    width: 100% !important;
    overflow: auto;
}

.retailer-list-table-header-bg {
    background-color: #EFF7FF;
    padding: 0px 0px 0px 10px !important;
}

.retailer-list-table-header-bg .header-text {
    color: var(--accent-color) !important;
    /* font-family: 'Satoshi-Bold' !important; */
    font-size: 14px !important;
    letter-spacing: 0.5px !important;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.retailer-list-table-header-bg .header-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.retailer-list-table-header-bg .up-icon {
    margin-top: 15px;
    color: #79A4D9;
}

.retailer-list-table-header-bg .down-icon {
    margin-top: -22px;
    color: #79A4D9;
}

.retailer-list-table td {
    font-family: 'Satoshi-Regular' !important;
    font-size: 14px !important;
    font-weight: 600;
    letter-spacing: 0.5px;
    padding: 0px;
    text-align: center;
    border: 0;
}

.retailer-list-table .wallet-point {
    border: 0;
    background-color: #D1EDFF;
    border-radius: 22px;
    color: var(--global-color);
    font-weight: 600;
    padding: 5px 18px 5px 18px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.retailer-list-table tr:nth-child(even) {
    background-color: #F7F7F7;
}

.retailer-list-table .wallet-point img {
    vertical-align: text-bottom;
}


.retailer-list-table .status-unblock {
    border: 0;
    background-color: #5E9340;
    color: var(--white) !important;
    border-radius: 22px;
    color: var(--global-color);
    font-weight: 600;
    padding: 5px 12px 5px 12px;
    letter-spacing: 1px;
}

.retailer-list-table .status-block {
    border: 0;
    background-color: #b10a1a;
    color: var(--white) !important;
    border-radius: 22px;
    color: var(--global-color);
    font-weight: 600;
    padding: 5px 12px 5px 12px;
    width: 80px;
    letter-spacing: 1px;
}

.status-null {
    border: 0;
    background-color: transparent;
}

@media(max-width:576px) {
    .retailer-list-form {
        width: max-content;
        overflow-x: auto;
    }
}