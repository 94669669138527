.finance-locker-box .finance-label {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
}
.active-btn{
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 16px;
    color: white;
    background-color: green; 
}
.status-btn{
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 16px;
    color: white;
    background-color: #609340; 
}
.inactive-btn{
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 16px;
    color: white;
    background-color: #b00b1b; 
}
.finance-locker-btns-wrap {
    display: flex;
    align-items: baseline;
    justify-content: end;
    gap: 10px;
    margin-top: 30px;
}

.finance-locker-btns-wrap .refresh-icon {
    font-size: 45px;
    color: var(--global-color);
    border: 1px solid #3B5776;
    border-radius: 8px;
    padding: 10px;
}

.finance-locker-btns-wrap .cancel-btn {
    border: 1px solid var(--global-color);
    background-color: transparent;
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 65px;
    letter-spacing: 0.8px;
}

.finance-locker-btns-wrap .submit-btn {
    border: 0;
    background-color: var(--global-color);
    color: var(--white);
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 65px;
    letter-spacing: 0.8px;
}

.finance-locker-input-wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 10px;
    margin-top: -25px;
}

.finance-locker-input-wrap .input-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    
}
.finance-locker-input-wrap .input-wrap input::placeholder{
    font-size: 13px;
}
.finance-locker-input-wrap .suggestion-down-icon {
    position: absolute;
    right: 10px;
}

.finance-locker-input-wrap input {
    border: 0;
    width: 100%;
    border-radius: 8px;
    padding: 12px;
}

.finance-locker-box {
    margin-top: -20px;
}

.finance-locker-device-details-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.finance-locker-device-details {
    display: flex;
    width: 50%;
}

.new-phone-text h2 {
    font-size: 20px;
}

.device-image h2 {
    font-size: 16px;
    margin-bottom: 25px;
}

.device-details-container {
    display: flex;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 4px 4px 20px 0px rgba(96, 96, 96, 0.12);
    padding: 20px;
    color: #333;
    border: 1px solid #EFF7FF;
    justify-content: space-between;
}

.device-details-container1 {
    display: flex;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 4px 4px 20px 0px rgba(96, 96, 96, 0.12);
    padding: 20px;
    color: #333;
    border: 1px solid #EFF7FF;
    justify-content: space-between;
}

.device-image img {
    max-width: 100%;
}

.device-info {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.device-info1 {
    width: 100%;
}

.device-info h2 {
    margin-bottom: 1rem;
}

.device-action {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}
.status-dropdown{
    width: 100%;
    border-radius: 8px;
    padding: 12px;
    background-color: #ffffff;
    border: 1px solid #EFF7FF;
}
.status-dropdown option{
    padding: 12px;
    background-color: #ffffff;
    border: 1px solid #EFF7FF;
}

.action-btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.action-btn.lock {
    background-color: #79A545;
    font-size: 12px;
    border-radius: 18px;
    letter-spacing: 0.8px;
}

.action-btn.uninstall {
    background-color: var(--global-color);
    font-size: 12px;
    border-radius: 18px;
    letter-spacing: 0.8px;
}

.info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EFF7FF;
    padding: 20px 20px 20px 20px;
}

.info-row:last-child {
    border-bottom: none;
    padding: 20px 20px 10px 20px
}

.info-row.action {
    padding: 0px 20px 10px 20px;
}

.info-title {
    color: var(--global-color);
    font-size: 14px;
    letter-spacing: 0.8px;
    font-weight: bold;
    white-space: nowrap;
}

.info-text {
    color: #000;
    font-size: 14px;
    letter-spacing: 0.8px;
    font-weight: bold;
    white-space: nowrap;
}

.action-btn.unlock {
    background-color: #b10a1a;
    font-size: 12px;
    border-radius: 18px;
    letter-spacing: 0.8px;
}

.action-btn.none {
    background-color: transparent;
    color: #000;
}

.imei-suggestion-box-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.imei-suggestions-box {
    background-color: white;
    padding: 10px 18px 10px 18px;
    margin-top: 10px;
    border-radius: 8px;
}

.imei-list {
    border-bottom: 1px solid #EFF7FF;
}

.code-list {
    /* border-bottom: 1px solid #EFF7FF; */
}

.imei-suggestion {
    border-bottom: 1px solid #EFF7FF;
    padding: 10px 0px 10px 0px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    cursor: pointer;
}

.code-suggestion {
    border-bottom: 1px solid #EFF7FF;
    padding: 10px 0px 10px 0px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    cursor: pointer;
}

.imei-text {
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.code-text {
    color: #666;
}

.imei-suggestion:last-child {
    border-bottom: none;
}

.code-suggestion:last-child {
    border-bottom: none;
}

/* @media(max-width:1024px) {
    .device-info {
        width: 100%;
        overflow-x: auto;
    }
} */

/* @media(max-width:992px) {
    .device-info {
        width: 100%;
        overflow-x: auto;
    }
} */

/* @media(max-width:899px) {
    .device-details-container {
        flex-direction: column;
        align-items: center;
    }

    .device-info {
        width: 100%;
        overflow-x: auto;
    }
} */

@media(max-width:767px) {
    .finance-list-form {
        /* width: max-content; */
        /* overflow-x: auto; */
    }

    .finance-locker-input-wrap {
        margin-top: 0;
        flex-direction: column;
    }

    .finance-locker-btns-wrap {
        margin-top: 0px;
    }

    .device-image h2 {
        width: max-content;
        overflow-x: auto;
    }

    .device-details-container {
        flex-direction: column;
        width: 100%;
        overflow-x: auto;
    }

    .device-info {
        width: 100%;
        overflow-x: auto;
    }

    .info-row {
        gap: 10px;
    }

    .device-image img {
        max-width: 100%;
        display: flex;
        margin: 0 auto;
    }
}

@media(min-width:600px) and (max-width:700px) {
    .device-info1 {
        width: 100%;
        overflow-x: auto;
    }

    .device-image img {
        width: max-content;
    }

}

@media(min-width:577px) and (max-width:767px) {
    .finance-list-form {
        width: max-content;
        overflow-x: auto;
    }
}


@media(max-width:650px) {
    .device-details-container {
        width: 100%;
        overflow-x: auto;
    }

    .device-info {
        width: max-content;
        overflow-x: auto;
    }

    .device-info1 {
        width: max-content;
        overflow-x: auto;
    }
}

@media(max-width:576px) {
    .finance-list-form {
        width: max-content;
        overflow-x: auto;
    }

    .device-details-container {
        width: 100%;
        overflow-x: auto;
    }

    .device-info {
        width: max-content;
        overflow-x: auto;
    }

    .device-info1 {
        width: max-content;
        overflow-x: auto;
    }

}