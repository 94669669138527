.send-notification-input-wrap .send-notification-label {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
}

.send-notification-input {
    width: 100%;
}

.send-notification-input label {
    color: var(--text-color) !important;
    font-family: 'Satoshi-Regular' !important;
    letter-spacing: 0.8px !important;
    font-size: 16px !important;
}

.send-notification-btn {
    float: right;
    border: 0;
    background-color: var(--global-color);
    color: var(--white);
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 40px;
    letter-spacing: 0.8px;
    margin-top: 20px;
}