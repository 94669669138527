.email-input-wrap {
    display: flex;
    align-items: center;
    gap: 50px;
    width: 100%;
}

.email-container {
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9b5;
    border-radius: 5px;
    padding: 18px 15px;
    background-color: transparent;
    width: 100%;
}

.email-container input {
    border: none;
    outline: none;
    flex-grow: 1;
    background: none;
    font-size: 16px;
    color: #bec4ce;
}

.email-icon {
    width: 25px;
    height: 20px;
    color: var(--text-color)
}

.email-container {
    /* background-color: var(--accent-color); */
    background-color: #F3F3F3;
    border: 0;
    border-radius: 8px;
}

.email-container input::placeholder {
    color: var(--text-color) !important;
    font-family: 'Satoshi-Regular' !important;
    letter-spacing: 0.8px !important;
    font-size: 16px !important;
}

.reset-password-btn {
    width: 100%;
}

.reset-password-btn button {
    width: 100%;
    background-color: var(--global-color);
    border: none;
    border-radius: 12px;
    color: #fff;
    padding: 20px;
    font-size: 18px;
    letter-spacing: 1.5px;
    font-family: 'Satoshi-Regular' !important;
    font-weight: 600;
    cursor: pointer;
}

.back-to-login-text {
    display: flex;
    align-items: normal;
    justify-content: center;
    width: 100%;
    margin: 5px 0px 50px 0px;
    gap: 10px;
}

.back-to-login-text span {
    color: var(--global-color);
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 600;
}

.left-arrow{
    color: var(--global-color);
    vertical-align: bottom;
}


@media(max-width:576px) {

    .reset-password-btn button {
        padding: 15px;
        font-size: 15px;
    }

}