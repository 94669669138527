.sign-in-text h6 span {
    font-family: 'Satoshi-Bold';
}


.resend-text {
    color: var(--global-color);
    display: flex;
    align-items: normal;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 5px 0px 50px 0px;
}

.resend-text a {
    color: var(--global-color);
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 600;
    font-family: 'Satoshi-Bold';
    margin-left: 5px;
}

.resend-text span {
    color: var(--global-color);
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 600;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}