.add-distributor-btn {
    background-color: var(--global-color);
    color: var(--white);
    border-radius: 8px;
    border: 0;
    font-size: 16px;
    letter-spacing: 0.8px;
    padding: 15px 25px;
}

.retailer-input-wrap .reatiler-label {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
}

.retailer-input {
    width: 100%;
}

.retailer-input label {
    color: var(--text-color) !important;
    font-family: 'Satoshi-Regular' !important;
    letter-spacing: 0.8px !important;
    font-size: 16px !important;
}

.retailer-password-input {
    width: 100%;
}

.retailer-password-label {
    color: var(--text-color) !important;
    font-family: 'Satoshi-Regular' !important;
    letter-spacing: 0.8px !important;
    font-size: 16px !important;
}

.retailer-country-dropdown {
    width: 100%;
}

.retailer-btn-wrap {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 30px;
    margin-top: 50px;
}


.retailer-btn-wrap .cancel-btn {
    border: 1px solid var(--global-color);
    background-color: transparent;
    border-radius: 8px;
    font-size: 16px;
    padding: 20px 150px;
    letter-spacing: 0.8px;
}

.retailer-btn-wrap .submit-btn {
    border: 0;
    background-color: var(--global-color);
    color: var(--white);
    border-radius: 8px;
    font-size: 16px;
    padding: 20px 150px;
    letter-spacing: 0.8px;
}

@media(max-width:1199px) {
    .retailer-btn-wrap {
        flex-direction: column;
        gap: 12px;
        margin-top: 30px;
        align-items: center;
    }
}


@media(max-width:899px) {
    .retailer-input-wrap .reatiler-label {
        /* margin-bottom: 20px; */
    }

    .retailer-btn-wrap {
        flex-direction: column;
        gap: 12px;
        margin-top: 30px;
        align-items: center;
    }

    .retailer-btn-wrap .cancel-btn {
        padding: 15px 100px;
    }

    .retailer-btn-wrap .submit-btn {
        padding: 15px 100px;
    }
}

@media(max-width:576px) {

    .header-text {
        font-size: 20px;
    }

    .retailer-btn-wrap {
        flex-direction: column;
        gap: 12px;
        margin-top: 30px;
    }

    .retailer-btn-wrap .cancel-btn {
        padding: 15px 100px;
    }

    .retailer-btn-wrap .submit-btn {
        padding: 15px 100px;
    }

    .retailer-input-wrap .reatiler-label {
        margin-bottom: 0;
    }
}