.user-view-box {
    box-shadow: 4px 4px 20px 0px rgba(96, 96, 96, 0.12);
}

.user-view-box {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 4px 4px 20px 0px rgba(96, 96, 96, 0.12);
    /* padding: 20px; */
    color: #333;
    border: 1px solid #EFF7FF;
}

.user-view-box h6 {
    color: var(--global-color);
    font-size: 16px;
    font-family: 'Satoshi-bold';
    padding: 20px 0px 20px 20px;
    border-bottom: 1px solid #EFF7FF;
}

.user-detail {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.user-detail-wrap {
    display: flex;
    align-items: center;
    padding: 20px 20px;
}

.user-detail-wrap:not(:last-child) {
    border-bottom: 1px solid #EFF7FF;
}


.user-detail-wrap .detail-value {
    color: var(--global-color);
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.user-detail-wrap .user-detail .detail-label {
    color: var(--global-color);
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.user-detail-input-wrap {
    width: 50%;
}

.user-detail-input-wrap .detail-input {
    width: 100%;
    border: 1px solid #afc8d7ba;
    border-radius: 4px;
    padding: 8px;
}

.disatributer-update-button-wrap {
    width: 50%;
}

.disatributer-update-button {
    border: 0;
    background-color: var(--global-color);
    color: var(--white);
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 55px;
    letter-spacing: 0.8px;
    float: right;
}

@media(max-width:767px) {
    /* .user-detail {
        width: auto;
    }

    .user-detail-wrap {
        display: flex;
        align-items: start;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
    } */
}

@media(max-width:576px) {
    .user-detail-wrap {
        display: flex;
        align-items: start;
        /* flex-direction: column; */
        justify-content: space-between;
        gap: 10px;
        padding: 20px;
    }

    .user-view-box {
        width: max-content;
        overflow-x: auto;
    }

    .user-detail-input-wrap {
        width: auto;
    }

    .user-detail {
        width: auto;
    }

    .user-view-box1 {
        width: max-content;
        overflow-x: auto;
    }

}