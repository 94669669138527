.dashboard.user-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.dashboard.user-name {
    color: var(--global-color);
    font-size: 46px;
}

.dashboard.user-text h6 {
    color: var(--global-color);
    font-size: 18px;
    letter-spacing: 0.8px;
}

.dashboard-container {
    background-color: #F3F4F5;
    /* min-height: 100vh; */
}

.dashboard-scanner-wrap {
    display: flex;
}

.scanner-image {
    border: 1px solid #EAEBE9;
    border-radius: 12px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.image-container {
    display: flex;
    justify-content: end;
    align-items: flex-end;
    /* height: 100%; */
    width: 100%;
}

.image-container-1 {
    display: flex;
    justify-content: end;
    align-items: flex-end;
    height: 100%;
    /* height: max-content; */
    width: 100%;
}

.transaction-box-wrap .box-wrap {
    /* display: flex; */
    /* align-items: center; */
    /* gap: 10px; */
    width: 35%;
}

.transaction-box-wrap .box-wrap .box {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    height: auto;
    padding: 20px 20px 20px 20px;
    border-radius: 12px;
    background-color: #fff;
}


.customer-activity-box {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    height: auto;
    padding: 20px 20px 20px 20px;
    border-radius: 12px;
    width: 24%;
    background-color: #fff;
}

.customer-details {
    height: auto;
    padding: 20px 20px 20px 20px;
    border-radius: 12px !important;
    width: 50%;
    border-top: 1px solid #14283E;
    border-right: 1px solid #609340;
    border-bottom: 1px solid #14283E;
    border-left: 1px solid #609340;
    background-color: #fff;
}
.customer-details1 {
    height: auto;
    padding: 20px 20px 20px 20px;
    border-radius: 12px !important;
    width: 50%;
    border-top: 1px solid #14283E;
    border-right: 1px solid #609340;
    border-bottom: 1px solid #14283E;
    border-left: 1px solid #609340;
    background-color: #fff;
}


.customer-activity-box-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-top: 40px;
}

.customer-detail-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.customer-detail-box h5 {
    color: #0B151D;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
}

.customer-detail-box h6 {
    color: #0B151D;
    font-size: 24px;
    font-family: 'Satoshi-Bold';
}

.customer-activity-details {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.customer-activity-details h5 {
    color: #0B151D;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.customer-activity-details h6 {
    color: #0B151D;
    font-size: 24px;
    font-family: 'Satoshi-Bold';
}

.transaction-box-inner-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.transaction-box-wrap {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.customer-activity-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.transaction-box-inner-wrap h6 {
    color: var(--global-color);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.transaction-text-box {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.transaction-text-box h5 {
    color: var(--global-color);
    font-size: 40px;
    font-family: 'Satoshi-Bold';
}

.transaction-summary {
    color: var(--global-color);
    font-size: 26px;
    font-family: 'Satoshi-Bold';
    margin: 30px 0px 15px 0px;
}

.transaction-point-icon {
    width: 40px;
}

.activity-icon {
    width: 70px;
}

.dashboard-image {
    width: 100%;
    height: 100%;
}

.dashboard-container-wrap {
    display: flex;
    gap: 40px;
}

.dashboard-right {
    width: 115%;
}

.dashboard-left {
    width: 40%;
}

.image-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
}


@media (min-width: 1800px) {

    .dashboard-right {
        width: 200%;
    }

    .transaction-text-box h5 {
        color: var(--global-color);
        font-size: 50px;
    }

    .transaction-point-icon {
        width: auto;
    }

    .transaction-point-icon {
        width: 40px;
    }
}

@media(max-width:1199px) {

    .dashboard-right {
        width: 100%;
    }

    .dashboard-left {
        width: 100%;
    }

    .dashboard-container-wrap {
        flex-direction: column;
    }

    .transaction-box-wrap .box-wrap {
        width: 42%;
    }

    .transaction-box-wrap {
        flex-wrap: wrap;
        justify-content: start;
    }

    .scanner-image {
        width: auto;
    }

    .dashboard-image {
        width: auto;
    }

    .image-container {
        justify-content: center;
    }

    .image-container-1 {
        justify-content: center;
    }
}

@media(max-width:899px) {

    .dashboard-right {
        width: 100%;
    }

    .dashboard-left {
        width: 100%;
    }

    .dashboard-container-wrap {
        flex-direction: column;
    }

    .transaction-box-wrap .box-wrap {
        width: 42%;
    }

    .transaction-box-wrap {
        flex-wrap: wrap;
        justify-content: start;
    }

    .customer-activity-wrap {
        flex-wrap: wrap;
        justify-content: start;
    }

    .customer-activity-box {
        width: 28%;
    }

    .customer-activity-box-wrap {
        flex-wrap: wrap;
        justify-content: start;
    }

    .customer-details {
        width: 42%;
    }

    .scanner-image {
        width: auto;
    }

    .dashboard-image {
        width: auto;
    }

    .image-container {
        justify-content: center;
    }

    .image-container-1 {
        justify-content: center;
    }
}



@media(max-width:767px) {
    .dashboard-container {
        overflow: auto;
    }

    .dashboard-right {
        width: 100%;
    }

    .dashboard-left {
        width: 100%;
    }

    .dashboard.user-name {
        font-size: 25px;
    }

    .transaction-summary {
        font-size: 18px;
    }

    .dashboard.user-text h6 {
        font-size: 16px;
    }

    .transaction-box-wrap {
        flex-direction: column;
    }

    .transaction-box-wrap .box-wrap {
        width: auto;
    }

    .customer-activity-wrap {
        flex-direction: column;
    }

    .customer-activity-box {
        width: 100%;
    }

    .customer-activity-box-wrap {
        flex-direction: column;
    }

    .customer-details {
        width: 100%;
    }

    .transaction-box-wrap .box-wrap .box {
        width: auto;
    }

    .transaction-text-box h5 {
        font-size: 30px;
    }

    .customer-activity-details h6 {
        font-size: 30px;
    }

    .customer-detail-box h6 {
        font-size: 30px;
    }

    .scanner-image {
        width: auto;
    }

    .dashboard-image {
        width: auto;
    }

    .image-container {
        justify-content: center;
    }

    .image-container-1 {
        justify-content: center;
    }
}


@media(max-width:576px) {

    .sidebar-open-dashboard {
        width: max-content !important;
    }

    .dashboard-container {
        overflow: auto;
    }

    .dashboard-right {
        width: 100%;
    }

    .dashboard-left {
        width: 100%;
    }

    .dashboard.user-name {
        font-size: 22px;
    }

    .transaction-summary {
        font-size: 18px;
    }

    .dashboard.user-text h6 {
        font-size: 14px;
    }

    .transaction-box-wrap {
        flex-direction: column;
    }

    .transaction-box-wrap .box-wrap {
        width: auto;
    }

    .customer-activity-wrap {
        flex-direction: column;
    }

    .customer-activity-box {
        width: 100%;
    }

    .customer-activity-box-wrap {
        flex-direction: column;
    }

    .customer-details {
        width: 100%;
    }

    .transaction-box-wrap .box-wrap .box {
        width: auto;
    }

    .transaction-text-box h5 {
        font-size: 22px;
    }

    .customer-activity-details h6 {
        font-size: 22px;
    }

    .customer-detail-box h6 {
        font-size: 22px;
    }

    .activity-icon {
        width: 60px;
    }
}