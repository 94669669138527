.userlist-btns-wrap {
    display: flex;
    align-items: baseline;
    justify-content: end;
    gap: 10px;
    /* margin-top: 20px; */
}

.userlist-btns-wrap .refresh-icon {
    font-size: 45px;
    color: var(--global-color);
    border: 1px solid #3B5776;
    border-radius: 8px;
    padding: 10px;
}

.userlist-btns-wrap .cancel-btn {
    border: 1px solid var(--global-color);
    background-color: transparent;
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 55px;
    letter-spacing: 0.8px;
}

.userlist-btns-wrap .submit-btn {
    border: 0;
    background-color: var(--global-color);
    color: var(--white);
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 55px;
    letter-spacing: 0.8px;
}


.userlist-input-wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: column;
}

.userlist-input-wrap .userlist-label {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
}

.userlist-input-wrap .input-wrap {
    width: 100%;
}

.userlist-input-wrap input {
    border: 0;
    width: 100%;
    border-radius: 8px;
    padding: 16px;
}


.user-list-box .user-list-label {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
}

.user-details-dropdown {
    width: 100%;
    background-color: var(--white);
    border-radius: 8px;
}

.user-details-dropdown .user-list-dropdown {
    background-color: var(--white) !important;
}

.user-details-dropdown .user-details-dropdown-label {
    font-size: 12px !important;
    color: #817D7D !important;
    letter-spacing: 0.2px;
    padding-top: 5px;
}


.transaction-list-table .status-unblock {
    border: 0;
    background-color: #5E9340;
    color: var(--white) !important;
    border-radius: 22px;
    color: var(--global-color);
    font-weight: 600;
    padding: 5px 12px 5px 12px;
    letter-spacing: 1px;
}

.transaction-list-table .status-block {
    border: 0;
    background-color: #b10a1a;
    color: var(--white) !important;
    border-radius: 22px;
    color: var(--global-color);
    font-weight: 600;
    padding: 5px 12px 5px 12px;
    width: 80px;
    letter-spacing: 1px;
}

@media(max-width:1199px) {
    .header-text-wrap {
        /* margin-bottom: 0 !important; */
    }
}

@media(max-width:767px) {
    .userlist-btns-wrap {
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
    }
}

@media(max-width:700px) {
    .header-text-wrap {
        flex-direction: column;
        gap: 10px;
    }
}

@media(max-width:576px) {
    .user-list-form {
        width: max-content;
        overflow-x: auto;
    }
}