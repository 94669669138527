.password-input1 span {
    display: flex;
    justify-content: start;
    margin-top: 10px;
    font-size: 14px;
    color: var(--global-color);
    letter-spacing: 1px;
    font-weight: 600;
}
.user-iput{
    width: 100%;
}