.transfer-point-input-wrap .transfer-point-input-label {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
}

.transfer-input {
    width: 100%;
    /* padding: 18px !important; */
}

.transfer-input input {
    padding: 18px !important;
}

.transfer-input label {
    color: var(--text-color) !important;
    font-family: 'Satoshi-Regular' !important;
    letter-spacing: 0.8px !important;
    font-size: 16px !important;
}

.password-switch-wrap {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.profile-password-switch {
    margin-left: 0 !important;
}

.password-switch-wrap h6 {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
}

.form-switch {
    padding-left: 0;
}


.transfer-input-wrap .transfer-point-label {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
}


.select-role-dropdown {
    width: 100%;
}

.transfer-point-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;
    margin-top: 25px;
}

.transfer-point-btn-wrap .cancel-btn {
    border: 1px solid var(--global-color);
    background-color: transparent;
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 40px;
    letter-spacing: 0.8px;
}

.transfer-point-btn-wrap .transfer-point-btn {
    border: 0;
    background-color: var(--global-color);
    color: var(--white);
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 40px;
    letter-spacing: 0.8px;
}

.custom-toast {
    letter-spacing: 1px;
}


@media(max-width:576px) {

    .transfer-input-wrap .transfer-point-label {
        margin-bottom: 0;
    }

}