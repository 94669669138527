.header-text-wrap {
    text-align: center;
    margin-bottom: 20px;
}

.header-text {
    font-size: 24px;
    color: #1D3557;
    font-weight: bold;
}

.upload-section h3 {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    padding: 20px;
    letter-spacing: 0.5px;
}

.upload-box {
    padding: 30px;
    border-radius: 8px;
    background-color: #F3F3F3;
    margin-bottom: 10px;
}

.upload-placeholder {
    border: 2px dashed #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
}

.choose-file-btn {
    background-color: #6c757d;
    margin-top: 10px;
    color: white;
}

.file-size-info {
    font-size: 14px;
    font-weight: 600;
    color: var(--global-color);
    letter-spacing: 0.5px;
    margin-top: 10px;
}

.submit-btn {
    background-color: #1D3557;
    color: white;
    margin-top: 20px;
}

.uploaded-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.banner-submit-btn {
    float: right;
    border: 0;
    background-color: var(--global-color);
    color: var(--white);
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 40px;
    letter-spacing: 0.8px;
}

.banner-upload {
    margin: 0 auto !important;
    display: block !important;
}

.selected-file-box {
    margin-top: 10px;
}

.selected-file-text {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: var(--global-color);
    letter-spacing: 0.5px;
    margin: 10px 10px;
}

.selected-image {
    width: 100%;
    max-width: 250px;
    margin-top: 10px;
    max-height: 150px;
    margin: 0 auto;
    display: flex;
}

@media(max-width:1199px) {
    .file-size-info {
        margin-bottom: 20px;
    }
}

@media(max-width:700px) {
    .file-size-info {
        margin-bottom: 20px;
    }
}

@media(max-width:520px) {
    .upload-section-open {
        width: max-content;
        overflow-x: auto;
    }

    .file-size-info {
        margin-bottom: 20px;
    }
}