.password-input {
    width: 100%;
    /* padding-top: 10px !important; */
}

.password-input-wrap {
    width: 100%;
}

.MuiFilledInput-root {
    border-radius: 8px !important;
}

.MuiFilledInput-root::before {
    border-bottom: none !important;
}

.MuiFilledInput-root::after {
    border-bottom: none !important;
}

.password-label {
    color: var(--text-color) !important;
    font-family: 'Satoshi-Regular' !important;
    letter-spacing: 0.8px !important;
    font-size: 16px !important;
}

.sign-in-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.sign-in-text-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 50px 20px 0px 20px;
}

.main-img {
    width: 100%;
    align-self: end;
}

.sign-in-text img {
    margin-bottom: 40px;
}

.user-input {
    width: 100%;
}

.user-input label {
    color: var(--text-color) !important;
    font-family: 'Satoshi-Regular' !important;
    letter-spacing: 0.8px !important;
    font-size: 16px !important;
}

.sign-in-btn {
    width: 100%;
    margin-top: 20px;
}

.sign-in-btn button {
    width: 100%;
    background-color: var(--global-color);
    border: none;
    border-radius: 12px;
    color: #fff;
    padding: 20px;
    font-size: 18px;
    letter-spacing: 1px;
}

.sign-in-text h2 {
    font-size: 30px;
    letter-spacing: 1px;
    color: var(--global-color);
    margin-bottom: 20px;
}

.sign-in-text h6 {
    font-size: 20px;
    letter-spacing: 2px;
    color: var(--global-color);
    margin-bottom: 30px;
    line-height: 30px;
}

.check-box-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: -15px 0px 50px 0px;
}

.check-box-wrap span {
    color: var(--global-color);
    font-size: 14px;
    letter-spacing: 0.5px;
}

.check-box-wrap a {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.8px;
}

.download-i-secure-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

.download-i-secure-text img {
    vertical-align: bottom;
    margin-bottom: 0px;
}

.download-i-secure-text h4 {
    font-size: 20px;
    letter-spacing: 1px;
    color: var(--global-color);
}



@media (min-width: 1800px) {
    .sign-in-text-wrap {
        padding: 150px 130px 0px 130px;
    }

}

@media (max-width: 1300px) {

    .sign-in-text-wrap {
        padding: 50px 20px 0px 20px;
        height: auto;
    }


    .main-img {
        height: auto;
    }

    .i-secure-logo {
        height: 100px;
    }

    .sign-in-text img {
        margin-bottom: 25px;
    }

    .sign-in-text h2 {
        margin-bottom: 10px;
        font-size: 28px;
    }

    .download-i-secure-text img {
        margin-bottom: 45px;
    }

    .download-i-secure-text h4 {
        margin-bottom: 45px;
    }
}


@media (max-width: 1199.98px) {

    .sign-in-text-wrap {
        padding: 30px 20px 30px 20px;
        height: 100%;
    }

    .main-img {
        height: auto;
    }

    .download-i-secure-text {
        margin-bottom: 40px;
    }

    .sign-in-text {
        width: 60%;
    }
}


@media (max-width: 991.98px) {

    .sign-in-text-wrap {
        height: auto;
        padding: 30px 20px 30px 20px;
    }

    .main-img {
        height: auto;
    }

    .sign-in-text {
        width: 80%;
    }
}

@media(max-width:899px) {
    .sign-in-text {
        width: 70%;
    }
}

@media(max-width:767px) {
    .main-img {
        height: auto;
    }

    .sign-in-text-wrap {
        height: auto;
        padding: 30px 20px 30px 20px;
    }

    .sign-in-text {
        width: 100%;
    }
}

@media(max-width:576px) {
    .sign-in-text-wrap {
        height: auto;
        padding: 30px 20px 30px 20px;
    }

    .sign-in-text h2 {
        font-size: 25px;
        margin-bottom: 0px;
    }

    .sign-in-text img {
        width: 100px;
    }

    .sign-in-text h6 {
        font-size: 18px;
    }

    .sign-in-btn button {
        padding: 15px;
        font-size: 15px;
    }

    .download-i-secure-text img {
        width: 45px;
        vertical-align: bottom;
        margin-bottom: 0px;
    }

    .download-i-secure-text h4 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .check-box-wrap {
        margin: -15px 0px 20px 0px;
    }

    .main-img {
        height: auto;
    }
}