.search-data-bg {
    background-color: #EFF7FF;
    border-radius: 8px;
    padding: 20px 20px 30px 20px;
}

.total-count {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.total-count p {
    color: var(--global-color);
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.distributer-list-search-box .distributer-list-label {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
}

.distributer-details-dropdown {
    width: 100%;
    background-color: var(--white);
    border-radius: 8px;
}

.distributer-details-dropdown .distributer-dropdown {
    background-color: var(--white) !important;
}

.distributer-details-dropdown .distributer-dropdown-label {
    font-size: 12px !important;
    color: #817D7D !important;
    letter-spacing: 0.2px;
    padding-top: 5px;
}


.distributer-btns-wrap {
    display: flex;
    align-items: baseline;
    justify-content: end;
    gap: 18px;
    margin-top: 30px;
}

.distributer-btns-wrap .refresh-icon {
    font-size: 45px;
    color: var(--global-color);
    border: 1px solid #3B5776;
    border-radius: 8px;
    padding: 10px;
}

.distributer-btns-wrap .cancel-btn {
    border: 1px solid var(--global-color);
    background-color: transparent;
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 65px;
    letter-spacing: 0.8px;
}

.distributer-btns-wrap .submit-btn {
    border: 0;
    background-color: var(--global-color);
    color: var(--white);
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 65px;
    letter-spacing: 0.8px;
}

.distributer-list-table-wrap {
    width: 100% !important;
    overflow: auto;
}

.distributer-list-table-header-bg {
    background-color: #EFF7FF;
    padding: 0px 0px 0px 10px !important;
}

.distributer-list-table-header-bg .header-text {
    color: var(--accent-color) !important;
    /* font-family: 'Satoshi-Bold' !important; */
    font-size: 14px !important;
    letter-spacing: 0.5px !important;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.distributer-list-table-header-bg .header-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.distributer-list-table-header-bg .up-icon {
    margin-top: 15px;
    color: #79A4D9;
}

.distributer-list-table-header-bg .down-icon {
    margin-top: -22px;
    color: #79A4D9;
}

.distributer-list-table td {
    font-family: 'Satoshi-Regular' !important;
    font-size: 14px !important;
    font-weight: 600;
    letter-spacing: 0.5px;
    padding: 0px;
    text-align: center;
    border: 0;
}

.distributer-list-table .wallet-point {
    border: 0;
    background-color: #D1EDFF;
    border-radius: 22px;
    color: var(--global-color);
    font-weight: 600;
    padding: 5px 18px 5px 18px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.distributer-list-table .wallet-point img {
    vertical-align: text-bottom;
}

.distributer-list-table .status-unblock {
    border: 0;
    background-color: #5E9340;
    color: var(--white) !important;
    border-radius: 22px;
    color: var(--global-color);
    font-weight: 600;
    padding: 5px 12px 5px 12px;
    letter-spacing: 1px;
}

.distributer-list-table .status-block {
    border: 0;
    background-color: #b10a1a;
    color: var(--white) !important;
    border-radius: 22px;
    color: var(--global-color);
    font-weight: 600;
    padding: 5px 12px 5px 12px;
    width: 80px;
    letter-spacing: 1px;
}

.status-null {
    border: 0;
    background-color: transparent;
}

.distributer-list-action-dropdown {
    border: none !important;
}

.distributer-list-table tr:nth-child(even) {
    background-color: #F7F7F7;
}


.distributer-input-wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: column;
}


.distributer-input-wrap .distributer-label {
    color: var(--global-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    height: 25px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
}

.distributer-input-wrap .input-wrap {
    width: 100%;
}

.distributer-input-wrap input {
    border: 0;
    width: 100%;
    border-radius: 8px;
    /* padding: 16px; */
    padding: 16px 16px 14px 16px;
    /* background-color: #fff !important; */
}


@media(max-width:1400px) {
    .distributer-list-table .wallet-point {
        padding: 5px 8px 5px 8px;
    }
}


@media(max-width:1100px) {
    .distributer-list-table-wrap {
        width: max-content !important;
        /* overflow: auto; */
    }

    .distributer-list-table .wallet-point {
        border: 0;
        background-color: #D1EDFF;
        border-radius: 22px;
        color: var(--global-color);
        font-weight: 600;
        padding: 5px 18px 5px 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

@media(max-width:800px) {
    .distributer-btns-wrap {
        flex-direction: column;
    }
}

@media(max-width:767px) {
    .distributer-btns-wrap {
        flex-direction: column;
    }

    .distributer-list-table .wallet-point {
        border: 0;
        background-color: #D1EDFF;
        border-radius: 22px;
        color: var(--global-color);
        font-weight: 600;
        padding: 5px 18px 5px 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media(max-width:576px) {
    .sidebar-open-text {
        width: max-content !important;
    }

    .distributer-btns-wrap {
        flex-direction: column;
    }

    .distributer-btns-wrap .cancel-btn {
        padding: 15px 50px;
    }

    .distributer-btns-wrap .submit-btn {
        /* padding: 15px 50px; */
    }

    .search-data-bg {
        /* width: max-content;
        overflow-x: auto; */
        width: 100%;
        overflow-x: auto;
    }

    .distributer-list-form {
        width: max-content;
        overflow-x: auto;
    }
}